export interface Countries {
  [country: string]: Country[];
}
export interface Country {
  code: string;
  name: string;
  number: string;
}
export const allCountries: Countries = {
  fr: [
    { code: 'AF', name: 'Afghanistan', number: '004' },
    { code: 'AL', name: 'Albanie', number: '008' },
    { code: 'DZ', name: 'Algérie', number: '012' },
    { code: 'AS', name: 'Samoa américaines', number: '016' },
    { code: 'AD', name: 'Andorre', number: '020' },
    { code: 'AO', name: 'Angola', number: '024' },
    { code: 'AI', name: 'Anguilla', number: '660' },
    { code: 'AQ', name: 'Antarctique', number: '010' },
    { code: 'AG', name: 'Antigua-et-Barbuda', number: '028' },
    { code: 'AR', name: 'Argentine', number: '032' },
    { code: 'AM', name: 'Arménie', number: '051' },
    { code: 'AW', name: 'Aruba', number: '533' },
    { code: 'AU', name: 'Australie', number: '036' },
    { code: 'AT', name: 'Autriche', number: '040' },
    { code: 'AZ', name: 'Azerbaïdjan', number: '031' },
    { code: 'BS', name: 'Bahamas', number: '044' },
    { code: 'BH', name: 'Bahreïn', number: '048' },
    { code: 'BD', name: 'Bangladesh', number: '050' },
    { code: 'BB', name: 'Barbade', number: '052' },
    { code: 'BY', name: 'Bélarus', number: '112' },
    { code: 'BE', name: 'Belgique', number: '056' },
    { code: 'BZ', name: 'Belize', number: '084' },
    { code: 'BJ', name: 'Bénin', number: '204' },
    { code: 'BM', name: 'Bermudes', number: '060' },
    { code: 'BT', name: 'Bhoutan', number: '064' },
    { code: 'BO', name: 'Bolivie', number: '068' },
    { code: 'BQ', name: 'Bonaire, Saint-Eustache et Saba', number: '535' },
    { code: 'BA', name: 'Bosnie-Herzégovine', number: '070' },
    { code: 'BW', name: 'Botswana', number: '072' },
    { code: 'BV', name: 'Île Bouvet', number: '074' },
    { code: 'BR', name: 'Brésil', number: '076' },
    {
      code: 'IO',
      name: 'Territoire britannique',
      number: '086',
    },
    { code: 'BN', name: 'Brunéi Darussalam', number: '096' },
    { code: 'BG', name: 'Bulgarie', number: '100' },
    { code: 'BF', name: 'Burkina Faso', number: '854' },
    { code: 'BI', name: 'Burundi', number: '108' },
    { code: 'CV', name: 'Cap-Vert', number: '132' },
    { code: 'KH', name: 'Cambodge', number: '116' },
    { code: 'CM', name: 'Cameroun', number: '120' },
    { code: 'CA', name: 'Canada', number: '124' },
    { code: 'KY', name: 'Îles Caïmans', number: '136' },
    { code: 'CF', name: 'République centrafricaine', number: '140' },
    { code: 'TD', name: 'Tchad', number: '148' },
    { code: 'CL', name: 'Chili', number: '152' },
    { code: 'CN', name: 'Chine', number: '156' },
    { code: 'CX', name: 'Île Christmas', number: '162' },
    { code: 'CC', name: 'Île Cocos', number: '166' },
    { code: 'CO', name: 'Colombie', number: '170' },
    { code: 'KM', name: 'Comores', number: '174' },
    { code: 'CD', name: 'République démocratique du Congo', number: '180' },
    { code: 'CG', name: 'Congo', number: '178' },
    { code: 'CK', name: 'Île Cook', number: '184' },
    { code: 'CR', name: 'Costa Rica', number: '188' },
    { code: 'HR', name: 'Croatie', number: '191' },
    { code: 'CU', name: 'Cuba', number: '192' },
    { code: 'CW', name: 'Curaçao', number: '531' },
    { code: 'CY', name: 'Cyprus', number: '196' },
    { code: 'CZ', name: 'République Tchèque', number: '203' },
    { code: 'CI', name: "Côte d'Ivoire", number: '384' },
    { code: 'DK', name: 'Danmark', number: '208' },
    { code: 'DJ', name: 'Djibouti', number: '262' },
    { code: 'DM', name: 'Dominique', number: '212' },
    { code: 'DO', name: 'République Dominicaine', number: '214' },
    { code: 'EC', name: 'Équateur', number: '218' },
    { code: 'EG', name: 'Égypte', number: '818' },
    { code: 'SV', name: 'El Salvador', number: '222' },
    { code: 'GQ', name: 'Guinée équatoriale', number: '226' },
    { code: 'ER', name: 'Érythrée', number: '232' },
    { code: 'EE', name: 'Estonie', number: '233' },
    { code: 'SZ', name: 'Eswatini', number: '748' },
    { code: 'ET', name: 'Éthiopie', number: '231' },
    { code: 'FK', name: 'Îles Falkland (les) [Malvinas]', number: '238' },
    { code: 'FO', name: 'Îles Féroé (les)', number: '234' },
    { code: 'FJ', name: 'Fidji', number: '242' },
    { code: 'FI', name: 'Finlande', number: '246' },
    { code: 'FR', name: 'France', number: '250' },
    { code: 'GF', name: 'Guyane française', number: '254' },
    { code: 'PF', name: 'Polynésie française', number: '258' },
    { code: 'TF', name: 'Terres Australes Françaises (les)', number: '260' },
    { code: 'GA', name: 'Gabon', number: '266' },
    { code: 'GM', name: 'Gambie (la)', number: '270' },
    { code: 'GE', name: 'Géorgie', number: '268' },
    { code: 'DE', name: 'Allemagne', number: '276' },
    { code: 'GH', name: 'Ghana', number: '288' },
    { code: 'GI', name: 'Gibraltar', number: '292' },
    { code: 'GR', name: 'Grèce', number: '300' },
    { code: 'GL', name: 'Groenland', number: '304' },
    { code: 'GD', name: 'Grenade', number: '308' },
    { code: 'GP', name: 'Guadeloupe', number: '312' },
    { code: 'GU', name: 'Guam', number: '316' },
    { code: 'GT', name: 'Guatemala', number: '320' },
    { code: 'GG', name: 'Guernesey', number: '831' },
    { code: 'GN', name: 'Guinée', number: '324' },
    { code: 'GW', name: 'Guinée-Bissau', number: '624' },
    { code: 'GY', name: 'Guyane', number: '328' },
    { code: 'HT', name: 'Haïti', number: '332' },
    { code: 'HM', name: 'île Heard et îles McDonald', number: '334' },
    { code: 'VA', name: 'Saint-Siège (le)', number: '336' },
    { code: 'HN', name: 'Honduras', number: '340' },
    { code: 'HK', name: 'Hong Kong', number: '344' },
    { code: 'HU', name: 'Hongrie', number: '348' },
    { code: 'IS', name: 'Islande', number: '352' },
    { code: 'IN', name: 'Inde', number: '356' },
    { code: 'ID', name: 'Indonésie', number: '360' },
    { code: 'IR', name: "Iran (République islamique d'Iran", number: '364' },
    { code: 'IQ', name: 'Irak', number: '368' },
    { code: 'IE', name: 'Irlande', number: '372' },
    { code: 'IM', name: 'Île de Man', number: '833' },
    { code: 'IL', name: 'Israël', number: '376' },
    { code: 'IT', name: 'Italie', number: '380' },
    { code: 'JM', name: 'Jamaïque', number: '388' },
    { code: 'JP', name: 'Japon', number: '392' },
    { code: 'JE', name: 'Jersey', number: '832' },
    { code: 'JO', name: 'Jordanie', number: '400' },
    { code: 'KZ', name: 'Kazakhstan', number: '398' },
    { code: 'KE', name: 'Kenya', number: '404' },
    { code: 'KI', name: 'Kiribati', number: '296' },
    {
      code: 'KP',
      name: 'Corée ',
      number: '408',
    },
    { code: 'KR', name: 'Corée', number: '410' },
    { code: 'KW', name: 'Koweït', number: '414' },
    { code: 'KG', name: 'Kirghizistan', number: '417' },
    {
      code: 'LA',
      name: 'République démocratique populaire lao (la)',
      number: '418',
    },
    { code: 'LV', name: 'Lettonie', number: '428' },
    { code: 'LB', name: 'Liban', number: '422' },
    { code: 'LS', name: 'Lesotho', number: '426' },
    { code: 'LR', name: 'Libéria', number: '430' },
    { code: 'LY', name: 'Libye', number: '434' },
    { code: 'LI', name: 'Liechtenstein', number: '438' },
    { code: 'LT', name: 'Lituanie', number: '440' },
    { code: 'LU', name: 'Luxembourg', number: '442' },
    { code: 'MO', name: 'Macao', number: '446' },
    { code: 'MG', name: 'Madagascar', number: '450' },
    { code: 'MW', name: 'Malawi', number: '454' },
    { code: 'MY', name: 'Malaisie', number: '458' },
    { code: 'MV', name: 'Maldives', number: '462' },
    { code: 'ML', name: 'Mali', number: '466' },
    { code: 'MT', name: 'Malte', number: '470' },
    { code: 'MH', name: 'Îles Marshall (les)', number: '584' },
    { code: 'MQ', name: 'Martinique', number: '474' },
    { code: 'MR', name: 'Mauritanie', number: '478' },
    { code: 'MU', name: 'Maurice', number: '480' },
    { code: 'YT', name: 'Mayotte', number: '175' },
    { code: 'MX', name: 'Mexique', number: '484' },
    { code: 'FM', name: 'Micronésie', number: '583' },
    { code: 'MD', name: 'Moldavie', number: '498' },
    { code: 'MC', name: 'Monaco', number: '492' },
    { code: 'MN', name: 'Mongolie', number: '496' },
    { code: 'ME', name: 'Monténégro', number: '499' },
    { code: 'MS', name: 'Montserrat', number: '500' },
    { code: 'MA', name: 'Maroc', number: '504' },
    { code: 'MZ', name: 'Mozambique', number: '508' },
    { code: 'MM', name: 'Birmanie', number: '104' },
    { code: 'NA', name: 'Namibie', number: '516' },
    { code: 'NR', name: 'Nauru', number: '520' },
    { code: 'NP', name: 'Népal', number: '524' },
    { code: 'NL', name: 'Pays-Bas', number: '528' },
    { code: 'NC', name: 'Nouvelle-Calédonie', number: '540' },
    { code: 'NZ', name: 'Nouvelle-Zélande', number: '554' },
    { code: 'NI', name: 'Nicaragua', number: '558' },
    { code: 'NE', name: 'Niger ', number: '562' },
    { code: 'NG', name: 'Nigeria', number: '566' },
    { code: 'NU', name: 'Niue', number: '570' },
    { code: 'NF', name: 'Île Norfolk', number: '574' },
    { code: 'MP', name: 'Îles Mariannes du Nord', number: '580' },
    { code: 'NON', name: 'Norvège', number: '578' },
    { code: 'OM', name: 'Oman', number: '512' },
    { code: 'PK', name: 'Pakistan', number: '586' },
    { code: 'PW', name: 'Palaos', number: '585' },
    { code: 'PS', name: 'Palestine', number: '275' },
    { code: 'PA', name: 'Panama', number: '591' },
    { code: 'PG', name: 'Papouasie-Nouvelle-Guinée', number: '598' },
    { code: 'PY', name: 'Paraguay', number: '600' },
    { code: 'PE', name: 'Pérou', number: '604' },
    { code: 'PH', name: 'Philippines ', number: '608' },
    { code: 'PN', name: 'Pitcairn', number: '612' },
    { code: 'PL', name: 'Pologne', number: '616' },
    { code: 'PT', name: 'Portugal', number: '620' },
    { code: 'PR', name: 'Porto Rico', number: '630' },
    { code: 'QA', name: 'Qatar', number: '634' },
    { code: 'MK', name: 'République de Macédoine du Nord', number: '807' },
    { code: 'RO', name: 'Roumanie', number: '642' },
    { code: 'RU', name: 'Fédération de Russie ', number: '643' },
    { code: 'RW', name: 'Rwanda', number: '646' },
    { code: 'RE', name: 'Réunion', number: '638' },
    { code: 'BL', name: 'Saint Barthélemy', number: '652' },
    {
      code: 'SH',
      name: 'Sainte Hélène, Ascension et Tristan da Cunha',
      number: '654',
    },
    { code: 'KN', name: 'Saint-Kitts-et-Nevis', number: '659' },
    { code: 'LC', name: 'Sainte-Lucie', number: '662' },
    { code: 'MF', name: 'Saint Martin (partie française)', number: '663' },
    { code: 'PM', name: 'Saint Pierre et Miquelon', number: '666' },
    { code: 'VC', name: 'Saint-Vincent-et-les Grenadines', number: '670' },
    { code: 'WS', name: 'Samoa', number: '882' },
    { code: 'SM', name: 'Saint-Marin', number: '674' },
    { code: 'ST', name: 'Sao Tomé et Principe', number: '678' },
    { code: 'SA', name: 'Arabie Saoudite', number: '682' },
    { code: 'SN', name: 'Sénégal', number: '686' },
    { code: 'RS', name: 'Serbie', number: '688' },
    { code: 'SC', name: 'Seychelles', number: '690' },
    { code: 'SL', name: 'Sierra Leone', number: '694' },
    { code: 'SG', name: 'Singapour', number: '702' },
    { code: 'SX', name: 'Sint Maarten (partie néerlandaise)', number: '534' },
    { code: 'SK', name: 'Slovaquie', number: '703' },
    { code: 'SI', name: 'Slovénie', number: '705' },
    { code: 'SB', name: 'Îles Salomon', number: '090' },
    { code: 'SO', name: 'Somalie', number: '706' },
    { code: 'ZA', name: 'Afrique du Sud', number: '710' },
    {
      code: 'GS',
      name: 'Géorgie du Sud et les îles Sandwich du Sud',
      number: '239',
    },
    { code: 'SS', name: 'Sud Soudan', number: '728' },
    { code: 'ES', name: 'Espagne', number: '724' },
    { code: 'LK', name: 'Sri Lanka', number: '144' },
    { code: 'SD', name: 'Soudan ', number: '729' },
    { code: 'SR', name: 'Suriname', number: '740' },
    { code: 'SJ', name: 'Svalbard et Jan Mayen', number: '744' },
    { code: 'SE', name: 'Suède', number: '752' },
    { code: 'CH', name: 'Suisse', number: '756' },
    { code: 'SY', name: 'République arabe syrienne', number: '760' },
    { code: 'TW', name: 'Taïwan', number: '158' },
    { code: 'TJ', name: 'Tadjikistan', number: '762' },
    { code: 'TZ', name: 'Tanzanie', number: '834' },
    { code: 'TH', name: 'Thaïlande', number: '764' },
    { code: 'TL', name: 'Timor-Leste', number: '626' },
    { code: 'TG', name: 'Togo', number: '768' },
    { code: 'TK', name: 'Tokelau', number: '772' },
    { code: 'TO', name: 'Tonga', number: '776' },
    { code: 'TT', name: 'Trinité-et-Tobago', number: '780' },
    { code: 'TN', name: 'Tunisie', number: '788' },
    { code: 'TR', name: 'Turquie', number: '792' },
    { code: 'TM', name: 'Turkménistan', number: '795' },
    { code: 'TC', name: 'Îles Turques et Caïques ', number: '796' },
    { code: 'TV', name: 'Tuvalu', number: '798' },
    { code: 'UG', name: 'Ouganda', number: '800' },
    { code: 'UA', name: 'Ukraine', number: '804' },
    { code: 'AE', name: 'Emirats Arabes Unis ', number: '784' },
    {
      code: 'GB',
      name: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
      number: '826',
    },
    {
      code: 'UM',
      name: 'Îles mineures éloignées des États-Unis',
      number: '581',
    },
    { code: 'US', name: "États-Unis d'Amérique ", number: '840' },
    { code: 'UY', name: 'Uruguay', number: '858' },
    { code: 'UZ', name: 'Ouzbékistan', number: '860' },
    { code: 'VU', name: 'Vanuatu', number: '548' },
    {
      code: 'VE',
      name: 'Venezuela (République bolivarienne du)',
      number: '862',
    },
    { code: 'VN', name: 'Vietnam', number: '704' },
    { code: 'VG', name: 'Îles Vierges (britanniques)', number: '092' },
    { code: 'VI', name: 'Îles Vierges (États-Unis)', number: '850' },
    { code: 'WF', name: 'Wallis et Futuna', number: '876' },
    { code: 'EH', name: 'Sahara occidental', number: '732' },
    { code: 'YE', name: 'Yémen', number: '887' },
    { code: 'ZM', name: 'Zambie', number: '894' },
    { code: 'ZW', name: 'Zimbabwe', number: '716' },
    { code: 'AX', name: 'Îles Åland', number: '248' },
  ],
  en: [
    {
      name: 'Afghanistan',
      code: 'AF',
      number: '004',
    },
    {
      name: 'Åland Islands',
      code: 'AX',
      number: '248',
    },
    {
      name: 'Albania',
      code: 'AL',
      number: '008',
    },
    {
      name: 'Algeria',
      code: 'DZ',
      number: '012',
    },
    {
      name: 'American Samoa',
      code: 'AS',
      number: '016',
    },
    {
      name: 'Andorra',
      code: 'AD',
      number: '020',
    },
    {
      name: 'Angola',
      code: 'AO',
      number: '024',
    },
    {
      name: 'Anguilla',
      code: 'AI',
      number: '660',
    },
    {
      name: 'Antarctica',
      code: 'AQ',
      number: '010',
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
      number: '028',
    },
    {
      name: 'Argentina',
      code: 'AR',
      number: '032',
    },
    {
      name: 'Armenia',
      code: 'AM',
      number: '051',
    },
    {
      name: 'Aruba',
      code: 'AW',
      number: '533',
    },
    {
      name: 'Australia',
      code: 'AU',
      number: '036',
    },
    {
      name: 'Austria',
      code: 'AT',
      number: '040',
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
      number: '031',
    },
    {
      name: 'Bahamas',
      code: 'BS',
      number: '044',
    },
    {
      name: 'Bahrain',
      code: 'BH',
      number: '048',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
      number: '050',
    },
    {
      name: 'Barbados',
      code: 'BB',
      number: '052',
    },
    {
      name: 'Belarus',
      code: 'BY',
      number: '112',
    },
    {
      name: 'Belgium',
      code: 'BE',
      number: '056',
    },
    {
      name: 'Belize',
      code: 'BZ',
      number: '084',
    },
    {
      name: 'Benin',
      code: 'BJ',
      number: '204',
    },
    {
      name: 'Bermuda',
      code: 'BM',
      number: '060',
    },
    {
      name: 'Bhutan',
      code: 'BT',
      number: '064',
    },
    {
      name: 'Bolivia, Plurinational State of',
      code: 'BO',
      number: '068',
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      code: 'BQ',
      number: '535',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
      number: '070',
    },
    {
      name: 'Botswana',
      code: 'BW',
      number: '072',
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
      number: '074',
    },
    {
      name: 'Brazil',
      code: 'BR',
      number: '076',
    },
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
      number: '086',
    },
    {
      name: 'Brunei Darussalam',
      code: 'BN',
      number: '096',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
      number: '100',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
      number: '854',
    },
    {
      name: 'Burundi',
      code: 'BI',
      number: '108',
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
      number: '132',
    },
    {
      name: 'Cambodia',
      code: 'KH',
      number: '116',
    },
    {
      name: 'Cameroon',
      code: 'CM',
      number: '120',
    },
    {
      name: 'Canada',
      code: 'CA',
      number: '124',
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
      number: '136',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
      number: '140',
    },
    {
      name: 'Chad',
      code: 'TD',
      number: '148',
    },
    {
      name: 'Chile',
      code: 'CL',
      number: '152',
    },
    {
      name: 'China',
      code: 'CN',
      number: '156',
    },
    {
      name: 'Christmas Island',
      code: 'CX',
      number: '162',
    },
    {
      name: 'Cocos (Keeling) Islands',
      code: 'CC',
      number: '166',
    },
    {
      name: 'Colombia',
      code: 'CO',
      number: '170',
    },
    {
      name: 'Comoros',
      code: 'KM',
      number: '174',
    },
    {
      name: 'Congo',
      code: 'CG',
      number: '178',
    },
    {
      name: 'Congo, Democratic Republic of the',
      code: 'CD',
      number: '180',
    },
    {
      name: 'Cook Islands',
      code: 'CK',
      number: '184',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
      number: '188',
    },
    {
      name: "Côte d'Ivoire",
      code: 'CI',
      number: '384',
    },
    {
      name: 'Croatia',
      code: 'HR',
      number: '191',
    },
    {
      name: 'Cuba',
      code: 'CU',
      number: '192',
    },
    {
      name: 'Curaçao',
      code: 'CW',
      number: '531',
    },
    {
      name: 'Cyprus',
      code: 'CY',
      number: '196',
    },
    {
      name: 'Czechia',
      code: 'CZ',
      number: '203',
    },
    {
      name: 'Denmark',
      code: 'DK',
      number: '208',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
      number: '262',
    },
    {
      name: 'Dominica',
      code: 'DM',
      number: '212',
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
      number: '214',
    },
    {
      name: 'Ecuador',
      code: 'EC',
      number: '218',
    },
    {
      name: 'Egypt',
      code: 'EG',
      number: '818',
    },
    {
      name: 'El Salvador',
      code: 'SV',
      number: '222',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
      number: '226',
    },
    {
      name: 'Eritrea',
      code: 'ER',
      number: '232',
    },
    {
      name: 'Estonia',
      code: 'EE',
      number: '233',
    },
    {
      name: 'Eswatini',
      code: 'SZ',
      number: '748',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
      number: '231',
    },
    {
      name: 'Falkland Islands (Malvinas)',
      code: 'FK',
      number: '238',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
      number: '234',
    },
    {
      name: 'Fiji',
      code: 'FJ',
      number: '242',
    },
    {
      name: 'Finland',
      code: 'FI',
      number: '246',
    },
    {
      name: 'France',
      code: 'FR',
      number: '250',
    },
    {
      name: 'French Guiana',
      code: 'GF',
      number: '254',
    },
    {
      name: 'French Polynesia',
      code: 'PF',
      number: '258',
    },
    {
      name: 'French Southern Territories',
      code: 'TF',
      number: '260',
    },
    {
      name: 'Gabon',
      code: 'GA',
      number: '266',
    },
    {
      name: 'Gambia',
      code: 'GM',
      number: '270',
    },
    {
      name: 'Georgia',
      code: 'GE',
      number: '268',
    },
    {
      name: 'Germany',
      code: 'DE',
      number: '276',
    },
    {
      name: 'Ghana',
      code: 'GH',
      number: '288',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
      number: '292',
    },
    {
      name: 'Greece',
      code: 'GR',
      number: '300',
    },
    {
      name: 'Greenland',
      code: 'GL',
      number: '304',
    },
    {
      name: 'Grenada',
      code: 'GD',
      number: '308',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
      number: '312',
    },
    {
      name: 'Guam',
      code: 'GU',
      number: '316',
    },
    {
      name: 'Guatemala',
      code: 'GT',
      number: '320',
    },
    {
      name: 'Guernsey',
      code: 'GG',
      number: '831',
    },
    {
      name: 'Guinea',
      code: 'GN',
      number: '324',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
      number: '624',
    },
    {
      name: 'Guyana',
      code: 'GY',
      number: '328',
    },
    {
      name: 'Haiti',
      code: 'HT',
      number: '332',
    },
    {
      name: 'Heard Island and McDonald Islands',
      code: 'HM',
      number: '334',
    },
    {
      name: 'Holy See',
      code: 'VA',
      number: '336',
    },
    {
      name: 'Honduras',
      code: 'HN',
      number: '340',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
      number: '344',
    },
    {
      name: 'Hungary',
      code: 'HU',
      number: '348',
    },
    {
      name: 'Iceland',
      code: 'IS',
      number: '352',
    },
    {
      name: 'India',
      code: 'IN',
      number: '356',
    },
    {
      name: 'Indonesia',
      code: 'ID',
      number: '360',
    },
    {
      name: 'Iran, Islamic Republic of',
      code: 'IR',
      number: '364',
    },
    {
      name: 'Iraq',
      code: 'IQ',
      number: '368',
    },
    {
      name: 'Ireland',
      code: 'IE',
      number: '372',
    },
    {
      name: 'Isle of Man',
      code: 'IM',
      number: '833',
    },
    {
      name: 'Israel',
      code: 'IL',
      number: '376',
    },
    {
      name: 'Italy',
      code: 'IT',
      number: '380',
    },
    {
      name: 'Jamaica',
      code: 'JM',
      number: '388',
    },
    {
      name: 'Japan',
      code: 'JP',
      number: '392',
    },
    {
      name: 'Jersey',
      code: 'JE',
      number: '832',
    },
    {
      name: 'Jordan',
      code: 'JO',
      number: '400',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
      number: '398',
    },
    {
      name: 'Kenya',
      code: 'KE',
      number: '404',
    },
    {
      name: 'Kiribati',
      code: 'KI',
      number: '296',
    },
    {
      name: "Korea, Democratic People's Republic of",
      code: 'KP',
      number: '408',
    },
    {
      name: 'Korea, Republic of',
      code: 'KR',
      number: '410',
    },
    {
      name: 'Kuwait',
      code: 'KW',
      number: '414',
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
      number: '417',
    },
    {
      name: "Lao People's Democratic Republic",
      code: 'LA',
      number: '418',
    },
    {
      name: 'Latvia',
      code: 'LV',
      number: '428',
    },
    {
      name: 'Lebanon',
      code: 'LB',
      number: '422',
    },
    {
      name: 'Lesotho',
      code: 'LS',
      number: '426',
    },
    {
      name: 'Liberia',
      code: 'LR',
      number: '430',
    },
    {
      name: 'Libya',
      code: 'LY',
      number: '434',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
      number: '438',
    },
    {
      name: 'Lithuania',
      code: 'LT',
      number: '440',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
      number: '442',
    },
    {
      name: 'Macao',
      code: 'MO',
      number: '446',
    },
    {
      name: 'Madagascar',
      code: 'MG',
      number: '450',
    },
    {
      name: 'Malawi',
      code: 'MW',
      number: '454',
    },
    {
      name: 'Malaysia',
      code: 'MY',
      number: '458',
    },
    {
      name: 'Maldives',
      code: 'MV',
      number: '462',
    },
    {
      name: 'Mali',
      code: 'ML',
      number: '466',
    },
    {
      name: 'Malta',
      code: 'MT',
      number: '470',
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
      number: '584',
    },
    {
      name: 'Martinique',
      code: 'MQ',
      number: '474',
    },
    {
      name: 'Mauritania',
      code: 'MR',
      number: '478',
    },
    {
      name: 'Mauritius',
      code: 'MU',
      number: '480',
    },
    {
      name: 'Mayotte',
      code: 'YT',
      number: '175',
    },
    {
      name: 'Mexico',
      code: 'MX',
      number: '484',
    },
    {
      name: 'Micronesia, Federated States of',
      code: 'FM',
      number: '583',
    },
    {
      name: 'Moldova, Republic of',
      code: 'MD',
      number: '498',
    },
    {
      name: 'Monaco',
      code: 'MC',
      number: '492',
    },
    {
      name: 'Mongolia',
      code: 'MN',
      number: '496',
    },
    {
      name: 'Montenegro',
      code: 'ME',
      number: '499',
    },
    {
      name: 'Montserrat',
      code: 'MS',
      number: '500',
    },
    {
      name: 'Morocco',
      code: 'MA',
      number: '504',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
      number: '508',
    },
    {
      name: 'Myanmar',
      code: 'MM',
      number: '104',
    },
    {
      name: 'Namibia',
      code: 'NA',
      number: '516',
    },
    {
      name: 'Nauru',
      code: 'NR',
      number: '520',
    },
    {
      name: 'Nepal',
      code: 'NP',
      number: '524',
    },
    {
      name: 'Netherlands, Kingdom of the',
      code: 'NL',
      number: '528',
    },
    {
      name: 'New Caledonia',
      code: 'NC',
      number: '540',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
      number: '554',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
      number: '558',
    },
    {
      name: 'Niger',
      code: 'NE',
      number: '562',
    },
    {
      name: 'Nigeria',
      code: 'NG',
      number: '566',
    },
    {
      name: 'Niue',
      code: 'NU',
      number: '570',
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
      number: '574',
    },
    {
      name: 'North Macedonia',
      code: 'MK',
      number: '807',
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
      number: '580',
    },
    {
      name: 'Norway',
      code: 'NO',
      number: '578',
    },
    {
      name: 'Oman',
      code: 'OM',
      number: '512',
    },
    {
      name: 'Pakistan',
      code: 'PK',
      number: '586',
    },
    {
      name: 'Palau',
      code: 'PW',
      number: '585',
    },
    {
      name: 'Palestine, State of',
      code: 'PS',
      number: '275',
    },
    {
      name: 'Panama',
      code: 'PA',
      number: '591',
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
      number: '598',
    },
    {
      name: 'Paraguay',
      code: 'PY',
      number: '600',
    },
    {
      name: 'Peru',
      code: 'PE',
      number: '604',
    },
    {
      name: 'Philippines',
      code: 'PH',
      number: '608',
    },
    {
      name: 'Pitcairn',
      code: 'PN',
      number: '612',
    },
    {
      name: 'Poland',
      code: 'PL',
      number: '616',
    },
    {
      name: 'Portugal',
      code: 'PT',
      number: '620',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
      number: '630',
    },
    {
      name: 'Qatar',
      code: 'QA',
      number: '634',
    },
    {
      name: 'Réunion',
      code: 'RE',
      number: '638',
    },
    {
      name: 'Romania',
      code: 'RO',
      number: '642',
    },
    {
      name: 'Russian Federation',
      code: 'RU',
      number: '643',
    },
    {
      name: 'Rwanda',
      code: 'RW',
      number: '646',
    },
    {
      name: 'Saint Barthélemy',
      code: 'BL',
      number: '652',
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      code: 'SH',
      number: '654',
    },
    {
      name: 'Saint Kitts and Nevis',
      code: 'KN',
      number: '659',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
      number: '662',
    },
    {
      name: 'Saint Martin (French part)',
      code: 'MF',
      number: '663',
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
      number: '666',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
      number: '670',
    },
    {
      name: 'Samoa',
      code: 'WS',
      number: '882',
    },
    {
      name: 'San Marino',
      code: 'SM',
      number: '674',
    },
    {
      name: 'Sao Tome and Principe',
      code: 'ST',
      number: '678',
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
      number: '682',
    },
    {
      name: 'Senegal',
      code: 'SN',
      number: '686',
    },
    {
      name: 'Serbia',
      code: 'RS',
      number: '688',
    },
    {
      name: 'Seychelles',
      code: 'SC',
      number: '690',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
      number: '694',
    },
    {
      name: 'Singapore',
      code: 'SG',
      number: '702',
    },
    {
      name: 'Sint Maarten (Dutch part)',
      code: 'SX',
      number: '534',
    },
    {
      name: 'Slovakia',
      code: 'SK',
      number: '703',
    },
    {
      name: 'Slovenia',
      code: 'SI',
      number: '705',
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
      number: '090',
    },
    {
      name: 'Somalia',
      code: 'SO',
      number: '706',
    },
    {
      name: 'South Africa',
      code: 'ZA',
      number: '710',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      code: 'GS',
      number: '239',
    },
    {
      name: 'South Sudan',
      code: 'SS',
      number: '728',
    },
    {
      name: 'Spain',
      code: 'ES',
      number: '724',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
      number: '144',
    },
    {
      name: 'Sudan',
      code: 'SD',
      number: '729',
    },
    {
      name: 'Suriname',
      code: 'SR',
      number: '740',
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
      number: '744',
    },
    {
      name: 'Sweden',
      code: 'SE',
      number: '752',
    },
    {
      name: 'Switzerland',
      code: 'CH',
      number: '756',
    },
    {
      name: 'Syrian Arab Republic',
      code: 'SY',
      number: '760',
    },
    {
      name: 'Taiwan, Province of China',
      code: 'TW',
      number: '158',
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
      number: '762',
    },
    {
      name: 'Tanzania, United Republic of',
      code: 'TZ',
      number: '834',
    },
    {
      name: 'Thailand',
      code: 'TH',
      number: '764',
    },
    {
      name: 'Timor-Leste',
      code: 'TL',
      number: '626',
    },
    {
      name: 'Togo',
      code: 'TG',
      number: '768',
    },
    {
      name: 'Tokelau',
      code: 'TK',
      number: '772',
    },
    {
      name: 'Tonga',
      code: 'TO',
      number: '776',
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
      number: '780',
    },
    {
      name: 'Tunisia',
      code: 'TN',
      number: '788',
    },
    {
      name: 'Türkiye',
      code: 'TR',
      number: '792',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
      number: '795',
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
      number: '796',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
      number: '798',
    },
    {
      name: 'Uganda',
      code: 'UG',
      number: '800',
    },
    {
      name: 'Ukraine',
      code: 'UA',
      number: '804',
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
      number: '784',
    },
    {
      name: 'United Kingdom of Great Britain and Northern Ireland',
      code: 'GB',
      number: '826',
    },
    {
      name: 'United States of America',
      code: 'US',
      number: '840',
    },
    {
      name: 'United States Minor Outlying Islands',
      code: 'UM',
      number: '581',
    },
    {
      name: 'Uruguay',
      code: 'UY',
      number: '858',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
      number: '860',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
      number: '548',
    },
    {
      name: 'Venezuela, Bolivarian Republic of',
      code: 'VE',
      number: '862',
    },
    {
      name: 'Viet Nam',
      code: 'VN',
      number: '704',
    },
    {
      name: 'Virgin Islands (British)',
      code: 'VG',
      number: '092',
    },
    {
      name: 'Virgin Islands (U.S.)',
      code: 'VI',
      number: '850',
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
      number: '876',
    },
    {
      name: 'Western Sahara',
      code: 'EH',
      number: '732',
    },
    {
      name: 'Yemen',
      code: 'YE',
      number: '887',
    },
    {
      name: 'Zambia',
      code: 'ZM',
      number: '894',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
      number: '716',
    },
  ],
};
